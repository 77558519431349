import _ from "lodash"
import jsforce from "jsforce"
import sequential from "@/vendor/promise-sequential"
import {
  CXSalesForceUser,
  CXSalesForceCase,
  CXSalesForceRMAItem,
  CXSalesForceNote,
  CXSalesForceAttachment,
  CXSalesForceReturn,
  CXSalesForceCaseComment,
  CXSalesForceContact,
} from "./types"
import { cx } from "@/types"
import { fileToRawBase64String } from "@/helpers/encoding"
import { bool } from "aws-sdk/clients/signer"

const CXSalesForceUser_Enum = "User"
const CXSalesForceCase_Enum = "Case"
const CXSalesForceRMAItem_Enum = "RMA_Item__c"
const CXSalesForceNote_Enum = "Note"
const CXSalesForceAttachment_Enum = "Attachment"
const CXSalesForceCaseComment_Enum = "CaseComment"
const CXSalesForceContact_Enum = "Contact"

/**
 *
 *
 * @author Stephen Bunn
 * @export
 * @class SalesforceService
 */
export default class SalesforceService {
  private connection: jsforce.Connection
  /**
   *Creates an instance of SalesforceService.
   * @constructor
   * @memberof SalesforceService
   */
  constructor() {
    this.connection = new jsforce.Connection({
      instanceUrl: process.env.VUE_APP_JSFORCE_INSTANCE_URL,
      loginUrl: process.env.VUE_APP_JSFORCE_LOGIN_URL,
      proxyUrl: process.env.VUE_APP_JSFORCE_PROXY_URL,
    })
    this.connection.login(process.env.VUE_APP_JSFORCE_USERNAME, process.env.VUE_APP_JSFORCE_PASSWORD, error => {
      if (error) {
        console.error(error)
        throw error
      }
    })
  }

  /**
   *
   * @param {cx.Returns.Return} workingReturn
   * @param {string} returnAccountId
   * @param {string} userId
   * @returns
   * @memberof SalesforceService
   */
  async createReturn(
    workingReturn: cx.Returns.Return,
    returnAccountId: string,
    userId: string
  ): Promise<CXSalesForceReturn> {
    const userInfo: CXSalesForceUser = await this.connection
      .sobject(CXSalesForceUser_Enum)
      .findOne({ id: userId }, { AccountId: 1, ContactId: 1, Name: 1 })

    if (_.isNil(userInfo)) throw `user ${userId} not found`
    const caseQuantity = _.sumBy(workingReturn.rowData, "quantity")
    const userName = _.isNil(userInfo.ContactId) ? ` - Created By ${userInfo.Name}` : ""

    const recordResult = await this.connection.sobject<CXSalesForceCase>(CXSalesForceCase_Enum).create({
      RecordTypeId: "01230000000n2AjAAI",
      AccountId: workingReturn.account.salesforceId,
      ContactId: workingReturn.contactId || userInfo.ContactId,
      Status: "New",
      Origin: "Web",
      Subject: _.truncate(
        `${workingReturn.action.name} - ${workingReturn.productGroup.name} (${caseQuantity})${userName}`,
        { length: 255 }
      ),
      Description: workingReturn.description,
      Account_Name__c: workingReturn.location.billing.description,
      Account_Street__c: workingReturn.location.billing.address,
      Account_City__c: workingReturn.location.billing.city,
      Account_Zip_Postal_Code__c: workingReturn.location.billing.postalCode,
      Account_State_Province__c: workingReturn.location.billing.state,
      Account_Country__c: workingReturn.location.billing.country,
      Account_Phone__c: workingReturn.location.billing.phoneNumber,
      Invoice_To1__c: workingReturn.account.salesforceId,
      Ship_To__c: workingReturn.location.shipping.salesforceId,
      Ship_To_Name__c: workingReturn.location.shipping.description,
      Shipping_City__c: workingReturn.location.shipping.city,
      Shipping_Country__c: workingReturn.location.shipping.country,
      Shipping_State_Province__c: workingReturn.location.shipping.state,
      Shipping_Street__c: workingReturn.location.shipping.address,
      Shipping_Zip_Postalcode__c: workingReturn.location.shipping.postalCode,
      Ship_To_Phone__c: workingReturn.location.shipping.phoneNumber,
      RMA_Return_to__c: returnAccountId,
      RMA_Submitted__c: true,
      Return_Required__c: true,
      Return_Type__c: workingReturn.action.name,
      Division__c: workingReturn.productGroup.salesforceProduct
        ? workingReturn.productGroup.salesforceProduct.type
        : undefined,
      Tag__c: workingReturn.tag,
      customer_po__c: workingReturn.poNumber,
    } as CXSalesForceCase)

    const caseId: string = recordResult.success ? recordResult.id : ""
    const chunkedRowData: Array<Array<cx.Returns.Item>> = _.chunk(workingReturn.rowData, 200)
    const rmaItemChunks: Array<Array<CXSalesForceRMAItem>> = []
    if (!caseId) throw "Case creation failure!"

    _.forEach(chunkedRowData, (chunk: Array<cx.Returns.Item>) => {
      const rmaItems: Array<CXSalesForceRMAItem> = []
      _.forEach(chunk, (row: cx.Returns.Item) => {
        const rmaItem: CXSalesForceRMAItem = {
          Case1__c: caseId,
          Problem_Type__c: row.issue.type,
          Problem_Sub_Type1__c: row.issue.name,
          Problem_Description_Comments__c: _.truncate(row.description || "", {
            length: 255,
          }),
          RMA_Part_Type__c: workingReturn.productGroup.salesforceProduct
            ? workingReturn.productGroup.salesforceProduct.name
            : undefined,
          Quantity__c: row.quantity,
          Division__c: workingReturn.productGroup.salesforceProduct
            ? workingReturn.productGroup.salesforceProduct.type
            : undefined,
          Exception__c: ".", // HACK: required for "Refresh Warranty Info" custom button in Salesforce
        }

        if (workingReturn.productGroup.hasSerialNumber) {
          rmaItem.Serial_number__c = row.serialNumber
        } else {
          rmaItem.Part_Number__c = workingReturn.productGroup.salesforceProduct
            ? workingReturn.productGroup.salesforceProduct.name
            : undefined
        }
        rmaItems.push(rmaItem)
      })

      rmaItemChunks.push(rmaItems)
    })

    const caseLineIds = await sequential(
      rmaItemChunks.map(rmaItems => {
        return async () => {
          const results = await this.connection.sobject<CXSalesForceRMAItem>(CXSalesForceRMAItem_Enum).create(rmaItems)

          if (
            !_.every(
              _.map(results, item => item.success),
              Boolean
            )
          ) {
            this.connection.sobject<CXSalesForceCase>(CXSalesForceCase_Enum).destroy(caseId)
            throw results
          } else {
            return _.map(results, "id")
          }
        }
      })
    )

    const update = this.updateReturnStatus(caseId, "Submitted - Awaiting Receipt")
    const retrieve = this.getReturnCaseNumber(caseId)
    const updateResults = await Promise.all([retrieve, update])

    const caseNumber = updateResults[0]
    if (!caseNumber) throw "caseNumber is null or undefined"
    return {
      caseNumber: caseNumber,
      caseId: caseId,
      caseLineIds: _.flatten(caseLineIds),
    }
  }

  /**
   * Create a Note on a given parent record.
   *
   * @param {*} parentId The id of the parent record the note should be attached to
   * @param {*} userId The user id of the author
   * @param {*} title The title of the note
   * @param {*} body The body of the note
   * @param {boolean} [isPrivate=false] Flag which indicates if note is private
   * @returns The id of the created note
   * @memberof SalesforceService
   */
  async createNote(parentId: any, userId: any, title: any, body: any, isPrivate: boolean = false): Promise<string> {
    const response = await this.connection.sobject<CXSalesForceNote>(CXSalesForceNote_Enum).create({
      ParentId: parentId,
      CreatedById: userId,
      LastModifiedById: userId,
      OwnerId: userId,
      Title: title,
      Body: body,
      IsPrivate: isPrivate,
    })
    if (response.success) return response.id
    throw response.errors //if the request was unsuccessful, throw the error to be handled later
  }

  /**
   * Create an attachment on a given parent record.
   *
   * @param {string} parentId The id of the parent record the attachment is attached to
   * @param {string} userId The user who created the attachment
   * @param {File} body The file to attach
   * @param {string} description A description of the attachment
   * @param {boolean} [isPrivate=false] Flag which indicates if the attachment is private
   * @param {string} fileName Optional file name if you wish to upload it as different than the File object's name
   * @returns Promise of the id of the created attachment
   * @memberof SalesforceService
   */
  public async createAttachment(
    parentId: string,
    userId: string,
    body: File,
    description: string,
    isPrivate: boolean = false,
    fileName?: string
  ): Promise<string> {
    return this.connection
      .sobject<CXSalesForceAttachment>(CXSalesForceAttachment_Enum)
      .create({
        ParentId: parentId,
        CreatedById: userId,
        OwnerId: userId,
        Name: fileName ? fileName : body.name,
        Description: description,
        Body: await fileToRawBase64String(body),
        IsPrivate: isPrivate,
      })
      .then(response => {
        if (response.success) return response.id
        throw response.errors
      })
  }

  public deleteAttachment(id: string): Promise<any> {
    return this.connection.sobject<CXSalesForceAttachment>(CXSalesForceAttachment_Enum).delete(id)
  }

  public createCaseComment(supportCaseId: string, text: string, userId: string, isPublic: boolean = true) {
    return this.connection
      .sobject<CXSalesForceCaseComment>(CXSalesForceCaseComment_Enum)
      .create({
        ParentId: supportCaseId,
        CommentBody: text,
        CreatedById: userId,
        IsPublished: isPublic,
      })
      .then(response => {
        if (response.success) return response.id
        throw response.errors
      })
  }

  public getAccountContacts(id: string): Promise<any[]> {
    return this.connection
      .sobject<CXSalesForceContact>(CXSalesForceContact_Enum)
      .find<CXSalesForceContact>({ "Account.Id": id }, ["Id", "Name"])
  }

  /**
   *
   * @param {String} caseId
   * @param {String} status
   * @returns {Promise.<String, String>} The status of the given case.
   * @memberof SalesforceService
   */
  private async updateReturnStatus(caseId: string, status: string): Promise<void> {
    await this.connection.sobject<CXSalesForceCase>(CXSalesForceCase_Enum).update({
      Id: caseId,
      Status: status,
    })
  }

  /**
   *
   * @param {String} caseId
   * @returns {Promise.<String, String>} The case number of the given case Id.
   * @memberof SalesforceService
   */
  private async getReturnCaseNumber(caseId: string): Promise<string> {
    const result = await this.connection
      .sobject<CXSalesForceCase>(CXSalesForceCase_Enum)
      .findOne<CXSalesForceCase>({ id: caseId }, { CaseNumber: 1 })
    return result.CaseNumber!
  }
}
