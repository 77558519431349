import _ from "lodash"
import { ApolloClient, ApolloQueryResult } from "apollo-client"
import { FetchResult } from "apollo-link"
import { DocumentNode } from "graphql"

import { cx } from "@/types"
import * as schema from "@/graphql/__schema"
import { createProvider } from "@/plugins/apollo"
import SalesforceService from "@/services/salesforce"
import EngagementService from "@/services/engagement"
import AppService from "@/services/app"
import CxUtility from "./utility"
import S3Service from "../s3"
import { Auth0UserProfile } from "auth0-js"
import { ComponentResolver } from "ag-grid/dist/lib/components/framework/componentResolver"
import { VwReturnLocationsConnection } from "@/graphql/__schema"

// define queries used throughout the cx service
const queries: { [key: string]: Promise<DocumentNode> } = {
  getReturn: import("@/graphql/get-return.gql"),
  getReturnItems: import("@/graphql/get-return-items.gql"),
  getReturnLocations: import("@/graphql/get-return-locations.gql"),
  getProductGroups: import("@/graphql/get-product-groups.gql"),
  getProductGroupIssues: import("@/graphql/get-product-group-issues.gql"),
  getProductGroupActions: import("@/graphql/get-product-group-actions.gql"),
  searchActiveAccounts: import("@/graphql/search-active-accounts.gql"),
  getAccountLocations: import("@/graphql/get-account-locations.gql"),
  getLocationId: import("@/graphql/get-location-id.gql"),
}

const mutations: { [key: string]: Promise<DocumentNode> } = {
  createLocation: import("@/graphql/create-location.gql"),
  createAccountLocationMapping: import("@/graphql/create-account-location-mapping.gql"),
}

export default class CX {
  private readonly apollo: ApolloClient<any>
  private readonly salesforce: SalesforceService
  private readonly engagement: EngagementService
  private readonly application: AppService
  private readonly s3: S3Service

  constructor() {
    this.apollo = createProvider().defaultClient
    this.salesforce = new SalesforceService()
    this.engagement = new EngagementService()
    this.application = new AppService()
    this.s3 = new S3Service()
  }

  async getReturn(referenceNumber: string): Promise<cx.Returns.Return> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      variables: {
        caseNumber: referenceNumber,
      },
      query: await queries.getReturn,
    })
    const data: schema.SupportCase = response.data.supportCaseByCaseNumber!

    return CxUtility.BUILD_RETURN(data)
  }

  async getReturnItems(returnId: number): Promise<Array<cx.Returns.Item>> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      variables: {
        returnId,
      },
      query: await queries.getReturnItems,
    })
    const lines: Array<schema.SupportCaseLine> = response.data.allSupportCaseLines!.nodes
    return _.map(lines, item => {
      return CxUtility.BUILD_ITEM(item)
    })
  }

  async getProductGroups(): Promise<Array<cx.Returns.ProductGroup>> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      query: await queries.getProductGroups,
    })
    const productGroups = response.data.allProductGroups!.nodes

    return _.map(productGroups, productGroup => {
      return CxUtility.BUILD_PRODUCTGROUP(productGroup)
    })
  }

  async getProductGroupIssues(productId: number): Promise<Array<cx.Returns.Issue>> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      variables: { productId },
      query: await queries.getProductGroupIssues,
    })
    const issues: Array<schema.Issue> = _.map(
      response.data.productGroupById!.productGroupIssueMapsByProductGroupId!.nodes,
      node => node.issueByIssueId!
    )
    return _.map(issues, issue => {
      return CxUtility.BUILD_ISSUE(issue)
    })
  }

  async getProductGroupActions(productId: number): Promise<Array<cx.Returns.Action>> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      variables: { productId },
      query: await queries.getProductGroupActions,
    })
    return _.map(response.data.productGroupById!.productGroupActionMapsByProductGroupId.nodes, node => {
      return CxUtility.BUILD_ATTACHMENT(node.actionByActionId!)
    })
  }

  async searchActiveAccounts(
    searchString: string
  ): Promise<
    Array<{
      account: cx.Returns.Account
      billingLocation: cx.Returns.Location
    }>
  > {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      variables: { searchString },
      query: await queries.searchActiveAccounts,
    })
    const accounts: Array<schema.VwActiveAccount> = response.data.allVwActiveAccounts!.nodes
    return _.map(accounts, account => {
      return {
        account: CxUtility.BUILD_ACCOUNT(account),
        billingLocation: CxUtility.BUILD_LOCATION(
          _.mapKeys(account, (value: any, key: string) => {
            return _.lowerFirst(key.replace("billing", ""))
          }) as schema.VwAccountLocation
        ),
      }
    })
  }

  async getAccountLocations(accountId: number, useCache: boolean = true): Promise<Array<cx.Returns.Location>> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      variables: { accountId },
      fetchPolicy: useCache ? "cache-first" : "network-only",
      query: await queries.getAccountLocations,
    })

    return _.map(response.data.allVwAccountLocations!.nodes, location => {
      return CxUtility.BUILD_LOCATION(location)
    })
  }

  async getReturnLocations(): Promise<cx.Returns.Location[]> {
    const response = await this.apollo.query<schema.Query>({
      query: await queries.getReturnLocations,
    })
    return response.data.allVwReturnLocations!.nodes.map(vwReturnLocation => ({
      id: vwReturnLocation.returnLocationId || null,
      salesforceId: vwReturnLocation.salesforceId || undefined,
      description: vwReturnLocation.description || null,
      attn: vwReturnLocation.attn || null,
      address: vwReturnLocation.address || null,
      city: vwReturnLocation.city || null,
      state: vwReturnLocation.state || null,
      country: vwReturnLocation.country || null,
      postalCode: vwReturnLocation.postalCode || null,
      phoneNumber: vwReturnLocation.phoneNumber || null,
      derivedFullAddress: vwReturnLocation.derivedFullAddress || null,
    }))
  }

  async getLocationId(location: Partial<cx.Returns.Location>): Promise<number> {
    const filter = _.pickBy(
      _.mapValues(location, field => {
        if (!_.isNil(field)) {
          return { equalTo: field }
        }
      }),
      _.identity
    )
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      variables: { filter },
      query: await queries.getLocationId,
    })
    if (response.data.allLocations!.nodes.length > 0) {
      return response.data.allLocations!.nodes[0].id
    } else {
      throw new Error(`no matching locations for ${JSON.stringify(location)}`)
    }
  }

  async createLocation(locationData: cx.Returns.Location): Promise<number> {
    const response: FetchResult<schema.Mutation> = await this.apollo.mutate({
      variables: { ...locationData },
      mutation: await mutations.createLocation,
    })
    return response.data!.createLocation!.location!.id
  }

  async createAccountLocationMapping(accountId: number, locationId: number): Promise<void> {
    const response: FetchResult<schema.Mutation> = await this.apollo.mutate({
      variables: { accountId, locationId },
      mutation: await mutations.createAccountLocationMapping,
    })
  }

  /**
   * Creates a note specifically for a project.
   *
   * @param {String} content The content to include in the note
   * @param {String} userSalesforceId The creating user's salesforce id
   * @param {Number} projectId The cx project id
   * @param {String} projectSalesforceId The engagement salesforce id
   * @param {String} [title=null] The title of the note in salesforce, optional
   * @param {Boolean} [isPrivate=false] Flag to indicate if the note is private
   * @returns The created cx note
   * @memberof CXService
   */
  async createProjectNote(
    content: string,
    userSalesforceId: string,
    projectId: number,
    projectSalesforceId: string,
    title: string | null = null,
    isPrivate: boolean = false
  ): Promise<number> {
    const user = await this.application.getUserBySalesforceId(userSalesforceId)
    if (!_.isString(title)) {
      title = `Created by ${user.fullName} in Sensus CX`
    }

    // create note in Salesforce
    const noteSalesforceId = await this.salesforce.createNote(
      projectSalesforceId,
      userSalesforceId,
      title,
      content,
      isPrivate
    )

    // create note in CX
    const note = await this.application.createNote(noteSalesforceId, user.id, title, content, isPrivate)
    try {
      // create a cx project note mapping
      const noteMapNodeId = await this.engagement.createProjectNoteMap(projectId, note.id)
      try {
        return note.id
      } catch (error) {
        // delete note mapping from CX
        await this.engagement.deleteProjectNoteMap(noteMapNodeId)
        throw error
      }
    } catch (error) {
      // delete created note from CX
      await this.application.deleteNote(note.nodeId)
      // delete note in Salesforce
      //TODO: method in salesforce service does not currently exist
      //await this.salesforce.deleteNote(noteSalesforceId)
      throw error
    }
  }

  async createSupportCaseAttachment(
    supportCase: cx.Returns.Return,
    attachingUser: Auth0UserProfile,
    file: File,
    isPrivate: boolean = false
  ) {
    const salesforceAttachmentId = await this.salesforce.createAttachment(
      supportCase.salesforceId,
      String(_.last(_.split(attachingUser.user_id, "|"))),
      file,
      "",
      isPrivate
    )
    return Promise.all([
      this.application.createSupportCaseAttachment(
        supportCase.id!,
        salesforceAttachmentId,
        `attachment/salesforce/${salesforceAttachmentId}`,
        file.name,
        true
      ),
      this.s3.uploadFile(file, `attachment/salesforce/${salesforceAttachmentId}`),
    ])
      .then(results => {
        return results[0].data.createSupportCaseAttachmentMap.supportCaseAttachmentMap.attachmentByAttachmentId
      })
      .catch(async error => {
        await this.salesforce.deleteAttachment(salesforceAttachmentId)
        throw error
      })
  }

  async createEngagementAttachment(
    engagement: cx.StatusTracker.Project,
    attachingUser: Auth0UserProfile,
    file: File,
    isPrivate: boolean = false
  ) {
    const salesforceAttachmentId = await this.salesforce.createAttachment(
      engagement.salesforceId!,
      String(_.last(_.split(attachingUser.user_id, "|"))),
      file,
      "", // no description by default (same as salesforce UI)
      isPrivate
    )
    return Promise.all([
      this.application.createEngagementAttachment(
        engagement.id,
        salesforceAttachmentId,
        `attachment/salesforce/${salesforceAttachmentId}`,
        file.name,
        true
      ),
      //this.s3.uploadFile(file, `attachment/salesforce/${salesforceAttachmentId}`),
      this.engagement.sendAttachmentEmail(
        [{ name: engagement.user.name!, email: engagement.user.email! }],
        engagement.referenceNumber,
        salesforceAttachmentId,
        `${attachingUser.given_name} ${attachingUser.family_name}`
      ),
    ])
      .then(results => {
        return results[0].data.createEngagementAttachmentMap.engagementAttachmentMap.attachmentByAttachmentId
      })
      .catch(async error => {
        await this.salesforce.deleteAttachment(salesforceAttachmentId)
        throw error
      })
  }

  /**
   * creates an attachment for either a support case or an engagement.
   *   does the entire process: uploading to salesforce, S3, and making a database entry
   *
   * @param databaseId - the id of the support_case or engagement within the CX database
   * @param salesforceparentId - the salesforce id of the support case or engagement
   * @param userSalesforceId - the salesforce id of the user uploading the attachment
   * @param file - file to upload
   * @param attachmentType - the type of object the engagement is being attached to (either a support case or an engagement)
   * @param description - description of the attachment (salesforce allows but defaults to blank)
   * @param isPrivate - whether the attachment should be made private from other users
   */

  createComment(salesforceParentId: string, commentText: string, userSalesforceId: string) {
    return this.salesforce
      .createCaseComment(salesforceParentId, commentText, userSalesforceId)
      .then(salesforceCommentId =>
        this.application.createCaseComment(salesforceParentId, salesforceCommentId, commentText, userSalesforceId)
      )
  }
}
