import _ from "lodash"
import { ApolloClient, ApolloQueryResult } from "apollo-client"
import { FetchResult } from "apollo-link"
import { DocumentNode } from "graphql"
import axios from "axios"

import { cx } from "@/types"
import * as schema from "@/graphql/__schema"
import { createProvider } from "@/plugins/apollo"
import EngagementUtility from "./utility"

// define queries used throughout the engagement service
const queries: { [key: string]: Promise<DocumentNode> } = {
  allEngagementsCount: import("@/graphql/get-all-engagements-count.gql"),
  allEngagements: import("@/graphql/get-all-engagements.gql"),
  engagementDetails: import("@/graphql/get-engagement-details.gql"),
  engagementNotes: import("@/graphql/get-engagement-notes.gql"),
  engagementNotesCount: import("@/graphql/get-engagement-notes-count.gql"),
  engagementHistory: import("@/graphql/get-engagement-history.gql"),
  engagementStatusIds: import("@/graphql/get-engagement-status-ids.gql"),
  engagementFilterSets: import("@/graphql/get-engagement-filter-sets.gql"),
}
const mutations: { [key: string]: Promise<DocumentNode> } = {
  createEngagementNoteMap: import("@/graphql/create-engagement-note-map.gql"),
  deleteProjectNoteMap: import("@/graphql/delete-project-note-map.gql"),
}

export default class CX {
  private readonly apollo: ApolloClient<any>

  constructor() {
    this.apollo = createProvider().defaultClient
  }

  async getProjectsCount(queryFilters: cx.StatusTracker.IFilters): Promise<number> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      query: await queries.allEngagementsCount,
      variables: queryFilters,
    })
    return response.data.allEngagements!.totalCount || 0
  }

  async getProjects(
    queryFilters: cx.StatusTracker.IFilters,
    limit: number,
    offset: number
  ): Promise<Array<cx.StatusTracker.Project>> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      query: await queries.allEngagements,
      variables: _.assign(queryFilters, {
        limit: limit,
        offset: offset,
      }),
    })
    const engagements = response.data.allEngagements!.nodes || []
    return _.map(engagements, engagement => {
      return EngagementUtility.BUILD_PROJECT(engagement)
    })
  }

  async getProject(referenceNumber: string): Promise<cx.StatusTracker.Project> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      query: await queries.engagementDetails,
      variables: {
        referenceNumber,
      },
    })
    const engagement = response.data.engagementByReferenceNumber!
    return EngagementUtility.BUILD_PROJECT(engagement)
  }

  async getFilterSets(): Promise<object> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      query: await queries.engagementFilterSets,
    })
    return _.mapValues(
      _.keyBy(_.clone(response.data.allVwStatusTrackerFilterAttributes!.nodes), "attribute"),
      "valueArray"
    )
  }

  async getProjectStatusIds(): Promise<Array<number>> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      query: await queries.engagementStatusIds,
    })

    return _.flatten(
      _.map(response.data.allWorkflows!.nodes, workflow =>
        _.map(workflow.statusesByWorkflowId.nodes, status => status.id)
      )
    )
  }

  async getProjectHistory(id: number): Promise<Array<cx.StatusTracker.ProjectHistory>> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      query: await queries.engagementHistory,
      variables: {
        id,
      },
    })

    return _.map(response.data.allVwEngagementHistories!.nodes, history => {
      return {
        new: history.new!,
        old: history.old!,
        field: history.field!,
        _created: history._created!,
      }
    })
  }

  async getProjectNotes(
    referenceNumber: string,
    limit: number,
    offset: number
  ): Promise<Array<cx.StatusTracker.ProjectNote>> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      fetchPolicy: "network-only",
      query: await queries.engagementNotes,
      variables: {
        referenceNumber: referenceNumber,
        limit,
        offset,
      },
    })
    const notes = _.map(response.data.engagementByReferenceNumber!.engagementNoteMapsByEngagementId.nodes, noteMap => {
      return noteMap.noteByNoteId
    })

    return notes
      .map(note => {
        if (note && !_.isNil(note.userByCreatedBy)) {
          return {
            id: note.id,
            date: note._created,
            author: {
              name: note.userByCreatedBy!.fullName,
              avatar: note.userByCreatedBy!.avatarThumbnailUrl,
            },
            content: note.body!.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
          }
        }
        return null
      })
      .filter(Boolean) as Array<cx.StatusTracker.ProjectNote>
  }

  async getProjectNotesTotal(referenceNumber: string): Promise<number> {
    const response: ApolloQueryResult<schema.Query> = await this.apollo.query({
      query: await queries.engagementNotesCount,
      variables: {
        referenceNumber: referenceNumber,
      },
    })
    return _.get(response, "data.engagementByReferenceNumber.engagementNoteMapsByEngagementId.totalCount") || 0
  }

  async createProjectNoteMap(projectId: number, noteId: number): Promise<string> {
    const response: FetchResult<schema.Mutation> = await this.apollo.mutate({
      mutation: await mutations.createEngagementNoteMap,
      variables: {
        engagementId: projectId,
        noteId: noteId,
      },
    })
    return response.data!.createEngagementNoteMap!.engagementNoteMap!.nodeId
  }

  async deleteProjectNoteMap(nodeId: string): Promise<void> {
    await this.apollo.mutate({
      mutation: await mutations.deleteProjectNoteMap,
      variables: {
        nodeId: nodeId,
      },
    })
  }

  sendAttachmentEmail(
    to: { name: string; email: string }[],
    engagementNumber: string,
    attachmentId: string,
    uploaderName: string
  ) {
    return axios.post(new URL("/api/v1/email/attachment", process.env.VUE_APP_MIDDLEWARE_URL).toString(), {
      to: to,
      engagement: { name: engagementNumber, id: "" },
      attachment: { name: "", id: attachmentId, uploader: uploaderName },
    })
  }
}
