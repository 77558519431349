import { cx } from "@/types"
import * as schema from "@/graphql/__schema"
import _ from "lodash"
import moment from "moment"

export default class CxUtility {
  static BUILD_LOCATION(locationData: schema.Location | schema.VwAccountLocation): cx.Returns.Location {
    let location = {
      //@ts-ignore
      id: locationData.id || locationData.locationId || null, //id is not set on VwAccountLocation so it is set to null
      description: locationData.description || null,
      address: locationData.address || null,
      city: locationData.city || null,
      state: locationData.state || null,
      country: locationData.country || null,
      postalCode: locationData.postalCode || null,
      attn: locationData.attn || null,
      phoneNumber: locationData.phoneNumber || null,
    }

    if (_.has(locationData, "salesforceId") || _.has(locationData, "derivedFullAddress")) {
      const derivedLoc = <schema.VwAccountLocation>location
      location = {
        ...location,
        salesforceId: locationData.salesforceId || null,
        derivedFullAddress: locationData.derivedFullAddress || null,
      } as cx.Returns.Location
    }

    return location
  }
  static BUILD_RETURN(data: schema.SupportCase): cx.Returns.Return {
    const returnLocation = CxUtility.BUILD_LOCATION(
      data.locationByReturnLocationOverride || data.productGroupByProductGroupId!.locationByReturnLocationId!
    )

    return {
      id: data.id,
      salesforceId: data.salesforceId,
      referenceNumber: data.caseNumber!,
      description: data.description || null,
      account: {
        id: null,
        number: data.accountByAccountId?.accountNumber || null,
        name: data.accountByAccountId!.name,
      },
      action: {
        id: null,
        name: data.actionByRequestedActionId!.name,
        description: null,
      },
      status: {
        id: data.statusByStatusId!.id,
        workflowId: data.statusByStatusId!.workflowId!,
        name: data.statusByStatusId!.name,
        description: data.statusByStatusId!.description || null,
        alert: data.statusByStatusId!.showAlert,
      },
      tag: data.customerTag || null,
      poNumber: data.poNumber || null,
      replacementOrder: data.replacementOrder || null,
      location: {
        billing: CxUtility.BUILD_LOCATION(data.locationByCustomerBillId!),
        shipping: CxUtility.BUILD_LOCATION(data.locationByCustomerShipId!),
        return: returnLocation,
      },
      productGroup: {
        id: data.productGroupByProductGroupId!.id,
        name: data.productGroupByProductGroupId!.name,
        type: data.productGroupByProductGroupId!.type || null,
        description: data.productGroupByProductGroupId!.description || null,
        hasSerialNumber: data.productGroupByProductGroupId!.hasSerialnumber,
        returnLocation: CxUtility.BUILD_LOCATION(data.productGroupByProductGroupId!.locationByReturnLocationId!),
        salesforceProduct: data.productGroupByProductGroupId?.salesforceProductBySalesforceProductId,
      },
      attachments: _.map(data.supportCaseAttachmentMapsBySupportCaseId.nodes, attachmentData => {
        const attachment = attachmentData.attachmentByAttachmentId!
        return {
          id: attachment.id,
          name: attachment.name,
          key: attachment.key,
        }
      }),
      created: moment(data._created),
      updated: moment(data._updated),
    }
  }
  static BUILD_PRODUCTGROUP(productGroup: schema.ProductGroup): cx.Returns.ProductGroup {
    let productGroupType = {
      id: productGroup.id,
      type: productGroup.type || null,
      name: productGroup.name,
      description: productGroup.description || null,
      hasSerialNumber: productGroup.hasSerialnumber,
    }

    if (productGroup.salesforceProductBySalesforceProductId) {
      productGroupType = {
        ...productGroupType,
        salesforceProduct: {
          type: productGroup.salesforceProductBySalesforceProductId.type,
          name: productGroup.salesforceProductBySalesforceProductId.name,
        },
      } as cx.Returns.ProductGroup
    }

    if (productGroup.locationByReturnLocationId) {
      productGroupType = {
        ...productGroupType,
        returnLocation: CxUtility.BUILD_LOCATION(productGroup.locationByReturnLocationId),
      } as cx.Returns.ProductGroup
    }
    return productGroupType
  }
  static BUILD_ITEM(item: schema.SupportCaseLine): cx.Returns.Item {
    return {
      id: item.id,
      created: moment(item._created),
      updated: moment(item._updated),
      serialNumber: item.serialNumber!,
      itemNumber: item.itemNumber!,
      description: item.description || null,
      quantity: item.quantity || 1,
      issue: CxUtility.BUILD_ISSUE(item.issueByIssueId!),
      disposition: item.dispositionByDispositionId!.name,
    } as cx.Returns.Item
  }
  static BUILD_ISSUE(issue: schema.Issue): cx.Returns.Issue {
    return {
      id: issue.id,
      type: issue.type,
      name: issue.name,
      description: issue.description || null,
    } as cx.Returns.Issue
  }
  static BUILD_ATTACHMENT(action: schema.Action): cx.Returns.Action {
    return {
      id: action.id,
      name: action.name,
      description: action.description || null,
    } as cx.Returns.Action
  }
  static BUILD_ACCOUNT(account: schema.VwActiveAccount): cx.Returns.Account {
    return {
      id: account.accountId || null,
      name: account.accountName || "",
      number: account.accountNumber || null,
      salesforceId: account.accountSalesforceId || undefined, //undefined since the property is optional,
      isDistributor: account.accountIsDistributor || false,
    }
  }
}
