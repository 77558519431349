











import { Vue, Component } from "vue-property-decorator"

@Component({
  name: "loading-spinner",
})
export default class LoadingSpinner extends Vue {}
