import { cx } from "@/types"
import * as schema from "@/graphql/__schema"
import _ from "lodash"

export default class EngagementUtility {
  static BUILD_PROJECT(engagement: schema.Engagement): cx.StatusTracker.Project {
    let attachments: Array<cx.StatusTracker.Attachment> = []
    if (engagement.engagementAttachmentMapsByEngagementId) {
      attachments = _.map(
        engagement.engagementAttachmentMapsByEngagementId.nodes,
        attachment => attachment.attachmentByAttachmentId
      ).filter(Boolean) as Array<cx.StatusTracker.Attachment>
    }

    const comodityTypes = (engagement.commodityTypes ? engagement.commodityTypes.filter(Boolean) : []) as Array<string>

    return {
      id: engagement.id,
      salesforceId: engagement.salesforceId || null,
      referenceNumber: engagement.referenceNumber!,
      type: engagement.type!,
      _updated: engagement._updated,
      state: engagement.stateByStateId!.name,
      account: engagement.accountByAccountId!.name,
      vendor: engagement.vendor!,
      //integrations: engagement.integrations!,
      description: engagement.description || null,
      status: {
        id: engagement.statusByStatusId!.id,
        showAlert: engagement.statusByStatusId!.showAlert || false,
        isInternal: engagement.statusByStatusId!.isInternal || false,
      },
      customer: engagement.customer || null,
      user: {
        ...EngagementUtility.BUILD_CONTACT(
          engagement.userBySensusContactId ? engagement.userBySensusContactId.fullName || null : null,
          engagement.userBySensusContactId ? engagement.userBySensusContactId.email || null : null,
          engagement.userBySensusContactId ? engagement.userBySensusContactId.phoneNumber || null : null
        ),
        title: engagement.userBySensusContactId ? engagement.userBySensusContactId.title || null : null,
        avatarUrl: engagement.userBySensusContactId ? engagement.userBySensusContactId.avatarUrl || null : null,
      } as cx.StatusTracker.UserContact,
      contacts: {
        customer: EngagementUtility.BUILD_CONTACT(
          engagement.customerContactName || null,
          engagement.customerContactEmail || null,
          engagement.customerContactPhone || null
        ),
        distributor: EngagementUtility.BUILD_CONTACT(
          engagement.distributorContactName || null,
          engagement.distributorContactEmail || null,
          engagement.distributorContactPhone || null
        ),
        cis: EngagementUtility.BUILD_CONTACT(
          engagement.cisVendorContactName || null,
          engagement.cisVendorContactEmail || null,
          engagement.cisVendorContactPhone || null
        ),
      },
      applications: (engagement.applications ? engagement.applications.filter(Boolean) : []) as Array<string>,
      attachments: attachments,
      comodityTypes: comodityTypes || null,
      overviewRequested: engagement.overviewRequested || false,
      trainingRequested: engagement.trainingRequested || false,
    }
  }
  static BUILD_CONTACT(name: string | null, email: string | null, phone: string | null): cx.StatusTracker.BasicContact {
    //if(!name && !email && !phone) return null
    return {
      name: name || null,
      email: email || null,
      phone: phone || null,
    }
  }
}
