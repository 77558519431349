/**
 * This method accepts a file and converts it to a base64 encoded string using FileReader
 */
export const fileToBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

/**
 * FileReader generates a prefix 'data:[type];base64,' that needs to be stripped in some use cases
 */
export const fileToRawBase64String = async (file: File): Promise<string> => {
  const encoding = await fileToBase64(file)
  return (encoding as string).split(",")[1]
}
